.passage, .paragraph {

    @include margin-vertical($margin-vertical-mobile);
  }
  
  @include media-breakpoint-up(sm) {
    .passage, .paragraph {
      @include margin-vertical($margin-vertical-tablet);
    }
  }
  
  @include media-breakpoint-up(md) {
    .passage, .paragraph {
      @include margin-vertical($margin-vertical-desktop);
    }
  }

  // .project-list {
  //   margin-top: -465px;
  // }