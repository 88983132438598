// ********************

// nav icon hamburger menu

.icon-bar.top-bar, .icon-bar.middle-bar, .icon-bar.bottom-bar  {
	background-color: #fff;
}

.navbar-toggler {
	&:focus, &:active {
		outline: none;
    box-shadow: none;
	}
}


// nav msg

.navbar-message {
	padding: 0;
}

.nav-message {
	display: none;
	width: 100%;

	// height: 50px;
	background: #e9e9e9;
	color: #404040;

//	#gradient > $include directional(#c1cfd6; #ffffff; 45deg);

	.content-default,
	.content-avail {
		padding: 11px 0;
	}

	.btn.btn-sm {
		font-size: 1rem;
	}

	a.btn-close, a.btn-close:hover, a.btn-close:active, a.btn-close:focus {
		// font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
		// font-style: normal;
		font-weight: bold;
		font-size: 24px;

		line-height: 1.5;
		color: #404040;
		text-decoration: none;
		// border-bottom: 1px solid #404040;
		margin: 0 10px;
	}

	a.btn-redirect, a.btn-redirect:hover, a.btn-redirect:active, a.btn-redirect:focus {
		// font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
		// font-style: normal;

		line-height: 1.5;
		color: $brand-custom;
		text-decoration: none;
	}
}

.nav-item {
	margin-right: 20px;
}




//@media screen and (min-width: @screen-sm-min) { bootstrap 3
@include media-breakpoint-up(md) {
	.nav-message {

		.content-default,
		.content-avail {
			text-align: center;
		}
	}
}

.buffer-top {
	padding-bottom: 50px;
}
