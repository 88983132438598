/* ----------------------------
/  bob deployment 
/  --------------------------*/

body {
    font-family: "Ideal Sans SSm A", "Ideal Sans SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 18px;
    line-height: 1.6;
    color: #333333;
    background-color: #fff;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-family: "Ideal Sans SSm A", "Ideal Sans SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    line-height: 1.2;
    color: inherit;
  }
  
  /* bob main */
  
  body {
    font-weight: 400;
    font-style: normal;
    color: #333333;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  div#top {
    position: absolute;
    top: 0px;
    visibility: hidden;
    z-index: -1;
  }
  div#bottom {
    position: relative;
    top: -50px;
  }
  .container {
    padding: 0;
    overflow: hidden;
  }
  @media (max-width: 767px) {
    .container {
      width: auto;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  @media (max-width: 480px) {
    .container {
      width: auto;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .scroll-page.position-top > div:first-child {
    padding-top: 160px;
  }
  div,
  a,
  a:link,
  a:hover,
  a:visited,
  input,
  button,
  label {
    -webkit-tap-highlight-color: rgba(200, 200, 200, 0);
    /* alpha=0 disables default highlighting */
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    text-rendering: auto;
    font-family: "Ideal Sans SSm A", "Ideal Sans SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-bottom: 12px;
  }
  h1,
  .h1 {
    font-weight: 300;
  }
  h2,
  .h2 {
    font-weight: 300;
  }
  h3,
  .h3 {
    font-weight: 400;
  }
  h4,
  .h4 {
    font-weight: 700;
  }
  h1 small,
  .h1 small {
    font-size: 21.6px;
    font-weight: 300;
  }
  h2 small,
  .h2 small,
  h3 small,
  .h3 small,
  h4 small,
  .h4 small {
    font-size: 18px;
    font-weight: 300;
  }
  h1.legend {
    padding-bottom: 6px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 12px;
  }
  .font-thin {
    font-weight: 300;
  }
  .formal-content.terms a {
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    -o-hyphens: auto;
    hyphens: auto;
  }
  nobr {
    word-wrap: break-word;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    -o-hyphens: none;
    hyphens: none;
  }
  blockquote {
    margin-bottom: 12px;
    border-left: 0 none;
  }
  blockquote p {
    font-size: 22px;
    line-height: 1.5;
    font-weight: 300;
    font-style: italic;
    position: relative;
  }
  blockquote p:last-child {
    margin-bottom: 0;
  }
  blockquote p:before {
    position: absolute;
    top: -80px;
    left: -40px;
    z-index: -1;
    content: '„';
    font-size: 100px;
    font-weight: 700;
    font-style: italic;
    color: #eeeeee;
  }
  a:focus {
    outline: 0 none;
  }
  input[type=submit] {
    font-style: normal;
    font-weight: 300;
  }
  input,
  textarea,
  button {
    -webkit-font-smoothing: inherit;
  }
  .btn-custom {
    color: #fff;
    background-color: #9873D1;
    border-color: #8a60cb;
  }
  .btn-custom:focus,
  .btn-custom.focus {
    color: #fff;
    background-color: #7c4dc4;
    border-color: #4c2a81;
  }
  .btn-custom:hover {
    color: #fff;
    background-color: #7c4dc4;
    border-color: #6a3bb3;
  }
  .btn-custom:active,
  .btn-custom.active,
  .open > .dropdown-toggle.btn-custom {
    color: #fff;
    background-color: #7c4dc4;
    border-color: #6a3bb3;
  }
  .btn-custom:active:hover,
  .btn-custom.active:hover,
  .open > .dropdown-toggle.btn-custom:hover,
  .btn-custom:active:focus,
  .btn-custom.active:focus,
  .open > .dropdown-toggle.btn-custom:focus,
  .btn-custom:active.focus,
  .btn-custom.active.focus,
  .open > .dropdown-toggle.btn-custom.focus {
    color: #fff;
    background-color: #6a3bb3;
    border-color: #4c2a81;
  }
  .btn-custom:active,
  .btn-custom.active,
  .open > .dropdown-toggle.btn-custom {
    background-image: none;
  }
  .btn-custom.disabled,
  .btn-custom[disabled],
  fieldset[disabled] .btn-custom,
  .btn-custom.disabled:hover,
  .btn-custom[disabled]:hover,
  fieldset[disabled] .btn-custom:hover,
  .btn-custom.disabled:focus,
  .btn-custom[disabled]:focus,
  fieldset[disabled] .btn-custom:focus,
  .btn-custom.disabled.focus,
  .btn-custom[disabled].focus,
  fieldset[disabled] .btn-custom.focus,
  .btn-custom.disabled:active,
  .btn-custom[disabled]:active,
  fieldset[disabled] .btn-custom:active,
  .btn-custom.disabled.active,
  .btn-custom[disabled].active,
  fieldset[disabled] .btn-custom.active {
    background-color: #9873D1;
    border-color: #8a60cb;
  }
  .btn-custom .badge {
    color: #9873D1;
    background-color: #fff;
  }
  .btn-app {
    color: #fff;
    background-color: #2C87B8;
    border-color: #2778a3;
  }
  .btn-app:focus,
  .btn-app.focus {
    color: #fff;
    background-color: #22698f;
    border-color: #0e2c3d;
  }
  .btn-app:hover {
    color: #fff;
    background-color: #22698f;
    border-color: #1b5472;
  }
  .btn-app:active,
  .btn-app.active,
  .open > .dropdown-toggle.btn-app {
    color: #fff;
    background-color: #22698f;
    border-color: #1b5472;
  }
  .btn-app:active:hover,
  .btn-app.active:hover,
  .open > .dropdown-toggle.btn-app:hover,
  .btn-app:active:focus,
  .btn-app.active:focus,
  .open > .dropdown-toggle.btn-app:focus,
  .btn-app:active.focus,
  .btn-app.active.focus,
  .open > .dropdown-toggle.btn-app.focus {
    color: #fff;
    background-color: #1b5472;
    border-color: #0e2c3d;
  }
  .btn-app:active,
  .btn-app.active,
  .open > .dropdown-toggle.btn-app {
    background-image: none;
  }
  .btn-app.disabled,
  .btn-app[disabled],
  fieldset[disabled] .btn-app,
  .btn-app.disabled:hover,
  .btn-app[disabled]:hover,
  fieldset[disabled] .btn-app:hover,
  .btn-app.disabled:focus,
  .btn-app[disabled]:focus,
  fieldset[disabled] .btn-app:focus,
  .btn-app.disabled.focus,
  .btn-app[disabled].focus,
  fieldset[disabled] .btn-app.focus,
  .btn-app.disabled:active,
  .btn-app[disabled]:active,
  fieldset[disabled] .btn-app:active,
  .btn-app.disabled.active,
  .btn-app[disabled].active,
  fieldset[disabled] .btn-app.active {
    background-color: #2C87B8;
    border-color: #2778a3;
  }
  .btn-app .badge {
    color: #2C87B8;
    background-color: #fff;
  }
  .btn-sec {
    color: #fff;
    background-color: #924b50;
    border-color: #814247;
  }
  .btn-sec:focus,
  .btn-sec.focus {
    color: #fff;
    background-color: #703a3e;
    border-color: #2d1719;
  }
  .btn-sec:hover {
    color: #fff;
    background-color: #703a3e;
    border-color: #592e31;
  }
  .btn-sec:active,
  .btn-sec.active,
  .open > .dropdown-toggle.btn-sec {
    color: #fff;
    background-color: #703a3e;
    border-color: #592e31;
  }
  .btn-sec:active:hover,
  .btn-sec.active:hover,
  .open > .dropdown-toggle.btn-sec:hover,
  .btn-sec:active:focus,
  .btn-sec.active:focus,
  .open > .dropdown-toggle.btn-sec:focus,
  .btn-sec:active.focus,
  .btn-sec.active.focus,
  .open > .dropdown-toggle.btn-sec.focus {
    color: #fff;
    background-color: #592e31;
    border-color: #2d1719;
  }
  .btn-sec:active,
  .btn-sec.active,
  .open > .dropdown-toggle.btn-sec {
    background-image: none;
  }
  .btn-sec.disabled,
  .btn-sec[disabled],
  fieldset[disabled] .btn-sec,
  .btn-sec.disabled:hover,
  .btn-sec[disabled]:hover,
  fieldset[disabled] .btn-sec:hover,
  .btn-sec.disabled:focus,
  .btn-sec[disabled]:focus,
  fieldset[disabled] .btn-sec:focus,
  .btn-sec.disabled.focus,
  .btn-sec[disabled].focus,
  fieldset[disabled] .btn-sec.focus,
  .btn-sec.disabled:active,
  .btn-sec[disabled]:active,
  fieldset[disabled] .btn-sec:active,
  .btn-sec.disabled.active,
  .btn-sec[disabled].active,
  fieldset[disabled] .btn-sec.active {
    background-color: #924b50;
    border-color: #814247;
  }
  .btn-sec .badge {
    color: #924b50;
    background-color: #fff;
  }
  /*
  .glyphicons {
    vertical-align: baseline;
  }
  .glyphicons.glyphicons-gaia {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .glyphicons.glyphicons-gaia:before {
    content: "\E174";
  }
  */
  
  /* ***************************************
   * bob deployment
   * ***************************************/
  
  .navbar {
    border-color: rgb(39, 120, 164);
    background-color: rgb(44, 135, 184);
  }
  .navbar-logo {
    height: 50px;
    display: table-cell;
    vertical-align: middle;
    color: #fff;
    font-size: 26px;
    font-weight: 300;
    letter-spacing: 2px;
  }
//   @media (min-width: 700px) {
//     .navbar .container {
//       width: 680px;
//     }
//   }
  
  .project-head {
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .project-head .container {
    padding-top: 30px;
  }
  .project {
    padding: 20px 20px 40px 20px;
    border-top: 1px solid #e0e0e0;
  }
  .project:nth-of-type(odd) {
    background-color: #f9f9f9;
  }
//   @media (min-width: 700px) {
//     .project .container,
//     .project-head .container,
//     .project-list .container {
//       width: 680px;
//     }
//   }

  @media (min-width: 700px) {
    .project-head .container {
      width: 680px;
    }
  }
  
  .btn.active.focus, 
  .btn.active:focus, 
  .btn.focus, 
  .btn:active.focus, 
  .btn:active:focus, 
  .btn:focus {
    outline: 0 none;
  }
  .btn-open,
  .btn-deploy {
    width: 170px;
  }
  .glyphicon-refresh-animate {
    -animation: spin .7s infinite linear;
    -webkit-animation: spin2 .7s infinite linear;
  }
  @-webkit-keyframes spin2 {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
  }
  @keyframes spin {
    from { transform: scale(1) rotate(0deg); }
    to { transform: scale(1) rotate(360deg); }
  }
  
  .btn-open {
    display: inline-block;
    max-width: 170px;
    width: 100%;
    padding: 10px 0;
    text-align: center;
  }
  .btn-open a {
    text-decoration: none;
    border-bottom: 1px solid #337ab7;
  }
  .btn-open a:hover,
  .btn-open a:active,
  .btn-open a:focus {
    text-decoration: none;
    border-bottom: 1px solid #23527c;
  }
  
  /*
  .result-project {
    margin-top: 10px;
  }
  */
  .result-head {
    display: none;
  }
  .btn-remove {
    margin-left: 5px;
  }
  a.btn-collapse,
  a.btn-collapse:link,
  a.btn-collapse:visited,
  a.btn-remove,
  a.btn-remove:link,
  a.btn-remove:visited {
    color: #ccc;
  }
  a.btn-collapse:active,
  a.btn-collapse:hover,
  a.btn-collapse:focus,
  a.btn-remove:active,
  a.btn-remove:hover,
  a.btn-remove:focus {
    color: #808080;
  }
  
  
  