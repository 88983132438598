.project {

	.project-testing,
	.project-staging,
	.project-production {
		img {
			width: 100%;
			border: solid thin rgb(221, 221, 221);
			border-radius: 8px;
			max-height: 100%;
		}

		.image-box {
			border: solid thin rgb(221, 221, 221);
			border-radius: 9px;
			background-image: radial-gradient(circle farthest-corner at 10% 20%, rgba(226, 240, 254, 1) 0%, rgba(255, 247, 228, 1) 90%);
		}
	}

	.btn,
	.url-txt {
		margin-top: 10px;
	}

	.url-txt {
		font-size: 14px;
	}

	.env-heading,
	h3 {
		text-align: center;
	}

	.env-heading {
		font-size: 25px;
	}

	h3 {
		font-size: 40px;
		margin: 60px 0;
	}
}

.project-index {

	height: 75px;
	overflow-x: scroll;
	position: sticky;
	background-color: #2c87b8;
	z-index: 100;
	top: 0px;

	.horizontal-scroll-wrapper {
		white-space: nowrap;
		padding: 15px;

		span {
			margin-left: 20px;
			padding: 4px 10px 4px 10px;
			border-radius: 4px;
			background-color: $brand-app;

			a {
				color: #fff;
				text-decoration: none;
			}
		}
	}
}

.project-top-section {

	img,
	.project-top-section-badge {
		margin-left: 20px;
		margin-bottom: 13px;
	}

	.project-top-section-badge {
		display: inline;
		position: absolute;
		bottom: 60px;
		border-radius: 10px;
		height: 50px;
		background-color: rgb(113, 113, 113);
		font-size: 15px;
	}

}

.project-top-section {
	span {
		font-size: 14px;
		padding: 10px;
		margin: 0 8px;
		line-height: 3.4;
		border-radius: 38px;
	}
}

.project .is-live {
	background-color: #61CB7E;

	&:before {
		content: "LIVE";
		color: #fff;
		padding: 0 10px;
	}
}

.project .is-blankpage {
	background-color: #e0dede;

	&:before {
		content: "BLANK";
		color: #333;
		padding: 0 10px;

	}
}

.project .is-redirect {
	background-color: #e0dede;

	&:before {
		content: "REDIRECTED";
		color: #333;
		padding: 0 10px;
	}
}

.project .is-under-construction {
	background-color: #e0d100;

	&:before {
		content: "UNDER CONSTRUCTION";
		color: #333;
		padding: 0 10px;
	}
}

.project .is-with-cookies {
	background-color: #e9a615;

	&:before {
		content: "COOKIES";
		color: #333;
		padding: 0 10px;
	}
}