// form

.container-form {
  position: relative;
	padding: 30px;
	min-width: 300px;
	width: 600px;
}

@include media-breakpoint-down(md) {

	.container-form {
		width: auto;
	}

}

legend {
  font-size: 30px;
  font-weight: 300;
  line-height: 1.2;

  padding-bottom: 6px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px;
}

label {
  font-weight: 300;
}

input, select, textarea {
  font-family: $font-family-base;
  font-style: italic;
}

textarea.form-control {
  resize: vertical;
}

.form-control.error {
  border: 1px solid #ff0000;
}

