.stripe {
	background-color: #F7F7F7;
	padding: 30px;

	.row {
		padding-left: 0;
	}

	h2 {
		text-align: center;
	}

	h2 {
		color: #2F2F2F;
	}

	.bob-btn-transparent {
		margin: 0 auto;
	}
}
