.paragraph {
//   padding: 30px 0;

}

.paragraph-img {
	background-color: lightblue;

	.img-default {
		display: block;
		height: auto;
		margin: 0 auto;
	}
}

// mobile first
.paragraph-img {

	.img-default {
		width: 100%;
		height: auto;
	}
	.img-align {
		float: none;
	}
}

@include media-breakpoint-up(sm) {

	.paragraph-img {

		 .img-align {
		 	float: none;
		 }
	}
}

@include media-breakpoint-up(md) {
	.paragraph-img {
		display: block;
	}
}


@include media-breakpoint-up(xl) {


	.paragraph:nth-child(2n+1) {
		.paragraph-img {
    	left: percentage((8 / $grid-columns));

			.img-align {
				float: right;
			}
		}

		.paragraph-copy {
    	right: percentage((4 / $grid-columns));
		}
	}
	.paragraph:nth-child(2n) {
		.img-align {
			float: left;
		}
	}

}
